<template>
  <template :key="route.fullPath" v-if="!pending">
    <component :is="pageTemplateType"></component>
  </template>

</template>
<script setup lang="ts">
definePageMeta({
  isr:{
    enabled: true,
    revalidate: 60*10
  }
});
const globalStore = useGlobalStore();
const templateName = ref("");
const route = useRoute();
const router = useRouter();
const page = ref(null);
const slug = route.params.slug[0] != "." ? route.params.slug[0] : route.params.slug.join("/");
const config = useRuntimeConfig();
// const path = route.fullPath.split('/').filter(v => v !== '').join('/');
const path = computed(() => {
  const p = route.fullPath.split('?')[0].split('/').filter(v => v !== '').join('/');
  return decodeURIComponent( p);
})
// const {data, pending} = await useFetch(`/api/pages/${path.value}`);
// const data = await GqlGetPage({uri: path.value});
const {data} = await useAsyncGql('getPage', {uri: path.value});
page.value = data?.value?.nodeByUri || null;
if (page.value?.seo?.fullHead) {
  const seo = JSON.parse(page.value?.seo?.fullHead) || null;
  if(!_IsEmpty(seo)){
    delete seo['schema'];
    seo.canonical = seo.canonical?.replace('admin.','');
    seo.og_url = seo.og_url?.replace('admin.','');
    useSeoMeta(seo);
  }
}

if (page.value) {
  globalStore.setCurrentPage(page.value);
  if (page?.value?.isTermNode) {
    templateName.value = "ProductsCategory";
    if (page.value?.__typename == "Make") {
      templateName.value = "MakePage";
    }
  } else {
    if (page.value.__typename == 'Post') {
      templateName.value = "Post";
    } else {
      templateName.value = page?.value?.template?.__typename?.replace("_", "");
    }
  }
}
const pageTemplateType = computed(() => {
  return resolveComponent(templateName?.value);
});
</script>
